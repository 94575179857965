
import { gsap } from "../node_modules/gsap";
import { ExpoScaleEase, RoughEase, SlowMo } from "../node_modules/gsap/EasePack";
import { Flip } from "../node_modules/gsap/Flip";
import { ScrollTrigger } from "../node_modules/gsap/ScrollTrigger";
import { Observer } from "../node_modules/gsap/Observer";
import { ScrollToPlugin } from "../node_modules/gsap/ScrollToPlugin";
import { Draggable } from "../node_modules/gsap/Draggable";
import { EaselPlugin } from "../node_modules/gsap/EaselPlugin";
import { MotionPathPlugin } from "../node_modules/gsap/MotionPathPlugin";
import { PixiPlugin } from "../node_modules/gsap/PixiPlugin";
import { TextPlugin } from "../node_modules/gsap/TextPlugin";
import { CustomEase } from "../node_modules/gsap/CustomEase";

gsap.registerPlugin(Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, EaselPlugin, MotionPathPlugin, PixiPlugin, TextPlugin, CustomEase, ExpoScaleEase, RoughEase, SlowMo);


// import swiper, { Navigation, Pagination, Autoplay, Controller, EffectCards,EffectCoverflow,EffectCreative,EffectCube,EffectFade,EffectFlip,FreeMode,Grid,HashNavigation,History,Keyboard,Lazy,Manipulation,Parallax,Scrollbar,Thumbs,Virtual,Zoom }  from "../node_modules/swiper";

// swiper.use([Navigation, Pagination, Autoplay, Controller, EffectCards,EffectCoverflow,EffectCreative,EffectCube,EffectFade,EffectFlip,FreeMode,Grid,HashNavigation,History,Keyboard,Lazy,Manipulation,Parallax,Scrollbar,Thumbs,Virtual,Zoom]);


